import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgChartsModule } from 'ng2-charts';
import { MainViewComponent } from "./main-view.component";
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
    declarations : [ MainViewComponent ],
    imports      : [BrowserModule, NgChartsModule, /*JWBootstrapSwitchModule,*/FileUploadModule],
})

export class MainViewModule {}
