// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
import 'core-js/actual/symbol';
import 'core-js/actual/object';
import 'core-js/actual/function';
import 'core-js/actual/parse-int';
import 'core-js/actual/parse-float';
import 'core-js/actual/number';
import 'core-js/actual/math';
import 'core-js/actual/string';
import 'core-js/actual/date';
import 'core-js/actual/array';
import 'core-js/actual/regexp';
import 'core-js/actual/map';
import 'core-js/actual/set';
import 'core-js/actual/reflect';

import 'core-js/actual/reflect';
import 'zone.js';

import '@angular/localize/init'
