<form #formFindTrip="ngForm">
  <div class="row" style="padding-top: 20px">

  </div>
  <div class="col-md-12">


    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Vendita Abbonamento</strong>
        </h2>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-xs-12">
            <p *ngIf="channel == '3'" class="col-xs-12 text-right" style="font-weight:900 ; font-size: 30px;"   [ngStyle]="{'color': avaiableCredit  >= 0  ? 'green' : 'red'}"  >Credito residuo: {{availableTrust + avaiableCredit | number : '1.2-2'}}   <i class="fas fa-euro-sign"></i> </p>
            <hr style="clear:both;" />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="col-xs-3">
              <div class="form-group">
                <label>
                  <i class="fas fa-map-marker-alt fa-2x"></i> PARTENZA </label>
                <select type="text" id="origin" class="form-control" ngModel name="origin" origin #origin="ngModel" (change)="onChangeStop()"
                  required [ngModel]="originId">
                  <option [ngValue]="">--</option>
                  <option *ngFor="let stop of originStops" value="{{stop.stopId}}">{{stop.stopName}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="form-group">
                <label>
                  <i class="fas fa-map-marker-alt fa-2x"></i> ARRIVO </label>
                <select type="text" id="destination" class="form-control" ngModel name="destination" destination #destination="ngModel" required
                  [ngModel]="destinationId">
                  <option [ngValue]="">--</option>
                  <option *ngFor="let stop of destinationStops" value="{{stop.stopId}}">{{stop.stopName}}</option>
                </select>
              </div>
            </div>


            <div class="col-xs-3">
              <div class="form-group">
                <label>
                  <i class="fas fa-users fa-2x"></i> TIPOLOGIA </label>

                <select type="text" id="categorySub" class="form-control" ngModel name="categorySub" categorySub #categorySub="ngModel" (ngModelChange)="onChangeSubscriptionType()"
                  required [ngModel]="categorySub">
                  <option *ngFor="let c of categories" value="{{c.categoryId}}">{{c.description}}</option>
                </select>

              </div>

            </div>
            
            <div *ngIf="isRoundTrip == false && subscriptionValue != '0'" class="col-xs-3">
              <div class="form-group">
                <label>
                  <i class="fas fa-calendar fa-2x"></i> ATTIVO DA: </label>
                <input *ngIf="isRoundTrip == false && subscriptionValue == '7'" class="form-control" id="bsDatepicker" name="bsDatepicker"
                  [(ngModel)]="datepickerModel" ngModel bsDatepicker #bsDatepicker placement="right" [bsConfig]="bsConfig"
                  [minDate]="minDate" placement="bottom" [maxDate]="maxDate" autocomplete="off" >

                <input *ngIf="isRoundTrip == false && (subscriptionValue == '365' || subscriptionValue == '1')" class="form-control" id="bsDatepicker" name="bsDatepicker"
                  [(ngModel)]="datepickerModel" ngModel bsDatepicker #bsDatepicker placement="right" [bsConfig]="bsConfig"
                  [minDate]="minDate" placement="bottom" [maxDate]="maxDate" autocomplete="off" >

                <select *ngIf="isRoundTrip == false && (subscriptionValue == '30' || subscriptionValue == '90' || subscriptionValue == '120')" type="text" id="monthly" class="form-control" ngModel name="monthly"
                  monthly #monthly="ngModel" (ngModelChange)="onChangeMontlyDate($event)" required [ngModel]="monthly">
                  <option *ngFor="let month of listMonths" value="{{getValueSelectMonth(month)}}">{{getNameMonth(month)}}</option>
                </select>

              </div>
            </div>

            <div class="col-xs-12">
              <div class="col-xs-12 text-center">
                <button (click)="choseTripList()" class="btn btn-info btn-lg">
                  <i class="fas fa-search-location"></i> Scegli </button>
                <br>
              </div>
            </div>

            <br>

          </div>
        </div>
        <div *ngIf="price == null" class="alert alert-info" role="alert" style="margin-top: 20px;">Nessun abbonamento per questa scelta</div>
        <div *ngIf="searchTripList && price != null && price != undefined" class="row">

          <br>
          <h1 class="text-center" style="font-weight: 800">Dettagli Abbonamento </h1>

          <hr style="clear:both;" />




          <div *ngIf="!isRoundTrip" class="col-xs-12 text-center">


            <div class="row">

              <div class="col-md-6">
                <h1>
                  <i class="fas fa-route"></i> LINEA: {{routeId}}</h1>

              </div>

              <div class="col-md-6">
                <h1> PREZZO: <span style="font-weight: 900"> {{price | number : '1.2-2'}} </span>
                  <i class="fas fa-euro-sign"></i>
                </h1>
              </div>


              <div class="col-xs-4">
              </div>

              <div class="col-xs-4">
                <div class="form-group">
                  <h1>
                      <i class="fas fa-id-card"></i> ID CARD </h1>

                  <input type="text" id="cardID" class="form-control" (ngModelChange)="cardIDChange($event)" ngModel name="cardID" cardID #cardID="ngModel" required>
               

                </div>


              </div>

              <div class="col-xs-4">
              </div>
              <br>
              <br>

            </div>






          </div>









        </div>
        <br>
        <br>
        <div *ngIf="searchTripList && !isRoundTrip && price != null" class="row">
          <div class="text-center">
            <button (click)="putIntoCart()" [disabled]="idTripA == -1 || idTripA == null || formFindTrip.value.cardID == '' "  class="btn btn-info btn-lg">
              <i class="fas fa-cart-arrow-down"></i> Inserisci nel Carrello</button>
          </div>

        </div>





        <div *ngIf="cart.length > 0" class="row">
          <br>
          <br>
          <h1 class="text-center" style="font-weight: 800">Carrello </h1>
          <hr style="clear:both;" />

        </div>

        <div *ngIf="cart.length > 0" class="row">

          <div class="col-xs-1">
          </div>

          <div class="col-xs-11">

            <div *ngFor="let i of cart" class="row">


              <div class="col-xs-2">
                <br>
                <i class="fas fa-ticket-alt fa-7x"></i>
              </div>

              <div class="col-xs-6">
                <h2>
                  Abbonamento alla linea: {{i.routeId}} </h2>

                <span>Valido Da:</span>
                <span style="font-size: 20px; font-weight: 900"> {{i.dateA | date:'dd/MM/yyyy'}} {{i.dateR | date:'dd/MM/yyyy'}}</span>
                <br>
                <div *ngIf="i.promotion.amount !== 0 && i.fare.amount > i.promotion.amount; else  descriptionElse">
                  <h4 *ngFor="let f of i.promotion.passengerList">{{f.descriptionPromotion}} x {{f.total}} </h4>
                </div>
                <ng-template #descriptionElse>
                  <span>Tipologia:</span>
                  <span style="font-size: 20px; font-weight: 900" *ngFor="let f of i.fare.passengerList">
                    {{f.categoryDescription}} x {{f.total}} </span>
                </ng-template>
                <br>
              </div>



              <div class="col-xs-2">
                <br>
                <br>
                <button class="btn btn-danger" (click)="removeFromCart(i)">
                  <i class="fas fa-cart-arrow-down"></i> Rimuovi</button>
              </div>

              <div class="col-xs-2">
                <br>
                <br>


                <div *ngIf="i.promotion.amount !== 0 && i.fare.amount > i.promotion.amount; else  contentElse">
                  <h1 style="font-weight: 900">{{i.promotion.amount}}
                    <i class="fas fa-euro-sign"></i>
                  </h1>

                </div>

                <ng-template #contentElse>
                  <h1 style="font-weight: 900">{{i.fare.amount}}
                    <i class="fas fa-euro-sign"></i>
                  </h1>
                </ng-template>
              </div>
              <hr style="clear:both;" />
            </div>

          </div>

          <hr style="clear:both;" />


          <div class="row">


            <div class="col-xs-1">

            </div>

            <div class="col-xs-9">
              <h1 style="font-weight: 900">TOTALE:
              </h1>

            </div>
            <div class="col-xs-2" style="padding-right: 0px">
              <h1 style="font-weight: 900; font-size: 40px">{{total}}
                <i class="fas fa-euro-sign"></i>
              </h1>
            </div>


            <br>
            <div class="col-xs-12">

              <div class="col-xs-1">
              </div>
              <div class="col-xs-2" style="padding-left: 0px">
                <label>Stampante</label>
                <select (change)="getSerialNumber($event.target.value)" type="text" id="idPrinter" class="form-control" ngModel name="idPrinter"
                  [ngModel]="defaultIdPrinter" required idPrinter #idPrinter="ngModel">
                  <option *ngIf="isPdfEnabled" [ngValue]="0"> Stampa come pdf </option>
                  <option *ngFor="let s of printerList" [ngValue]="s.id">{{s.name}}</option>
                </select>
                <br>


              </div>

              <div class="col-xs-9 text-right">
                <button (click)="initializeSoldTicket()" [disabled]="!idPrinter.valid" class="btn btn-info btn-lg">
                  <i class="fas fa-money-check"></i> Checkout</button>
              </div>

            </div>
            <br>

            <hr style="clear:both;" />
          </div>
        </div>


      </div>
    </div>
  </div>
</form>