export enum TicketType {
    ticketId = 'T',
    hopId = 'H',
    subId = 'S',
    ticketName = 'ENABLE_TICKET',
    hopName = 'ENABLE_HOPONHOPOFF',
    subName = 'ENABLE_SUBSCRIPTION',
    ticketValue = 'Ticket',
    hopValue = 'Hop-On Hop-Off',
    subValue = 'Abbonamento'
}

export enum RoundTrip {
    oneId = 'ONE',
    roundId = 'ROUNDTRIP',
    openId = 'OPEN',
    oneName = 'ROUNDTRIP_ONEWAY',
    roundName = 'ROUNDTRIP_TWOWAY',
    openName = 'ROUNDTRIP_OPEN',
    oneValue = 'Sola Andata',
    roundValue = 'Andata/Ritorno',
    openValue = 'Andata/Ritorno (data aperta)'
}

export enum TicketCategoryType {
    categoriesGroup = 'categories-grouped',
    category = 'category'
}