import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ScrollToBottomComponent } from './scroll-to-bottom/scroll-to-bottom.component';

@NgModule({
    declarations: [
        CheckboxGroupComponent, 
        ScrollToBottomComponent
    ],
    imports: [BrowserModule],
    exports: [
        CheckboxGroupComponent,
        ScrollToBottomComponent
    ]
})

export class ComponentsModule {}
