import { Component, OnInit, ViewChild , TemplateRef, OnChanges, SimpleChanges} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoggingService } from '../../../services/logging.service';
import { ListService } from '../../../services/list.service';
import swal2, { SweetAlertResult } from 'sweetalert2'
import { UtilsService } from '../../../services/utils.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export class VoucherListComponent implements OnInit {

  // FORM
  @ViewChild('formVoucherList', {static: true}) voucherListForm: NgForm;
  // OGGETTO LISTA ELEMENTI & FILTRI
  dataList: any[] = new Array();
  dataFilter: any[] = new Array();
  companiesList: any[] = new Array();
  typologyList: any[] = new Array();
  fareList: any[] = new Array();
  channelList: any[] = new Array();
  codetypeList: any[] = new Array();
  voucherStatusList: any[] = new Array();

  qParams = null;
  lotList: any[] = new Array();
  prepareShowList: any[] = new Array();
  showList: any[] = new Array();
  searchForDate = false;
  searchForLot = false;
  searchType = null;
  searchTsCreated = null;
  searchQuery = null;
  searchChannel = null;
  searchCodetype = null;
  searchStatus = 'VALIDATED';
  lotDescriptionName = '';

  details = null;

  // PATH CHIAMATE
  urlFaresPath = '/ticket/fare/fares';
  // urlVouchersListPath = '/ticket/type/types?subscription=no';
  urlVouchersListPath = '/voucher/vouchers';
  urlVouchersListCSVPath = '/voucher/vouchers/csv';
  serviceToCallPath = '/ticket/pricelist';
  urlCompaniesPath = '/company/companies';
  urlLotsPath = '/voucher/lots';
  urlDeletePath = '';
  errorMessage = '';
  visibleFilter = false ;
  defaultValue;
  UpOrDown;
  typeUser;
  isSoluzione1;
  isMobileOperator;
  roleUser;
  loaded = false;

  urlChannelsPaths = '/voucherEmitChannel/channels';
  urlCodetypesPaths = '/voucherEmitChannel/code-types';
  urlVoucherStatusPaths = '/multiselect/voucher/status';
  modalRef: BsModalRef;
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private utils: UtilsService,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute ,
    public listService: ListService ,
    private modalService: BsModalService) {
      this.listService.resetList();
      this.listService.configurationServiceCall('/voucher/list', false, true, 1, 20);
      this.listService.restURL = '/voucher/vouchers';

      if ( JSON.parse(sessionStorage.getItem('dataFilter')) ) {
        const receivedParams = JSON.parse(sessionStorage.getItem('dataFilter'));
        sessionStorage.removeItem('dataFilter');
        Object.keys(receivedParams).map( key => {
          this.dataFilter[key] = receivedParams[key];
        })
      }

      this.bsConfig = Object.assign(
        {},
        { containerClass: this.colorTheme },
        { dateInputFormat: "DD-MM-YYYY" },
        { rangeInputFormat: "DD-MM-YYYY" }
      );
    }

  ngOnInit() {
    this.showLoader();

    this.route.params.subscribe(async (params: Params) => {
      /**
       * When navigation occurs between lot vouchers details and vouchers list the page is the same but data has to be restored.
       * Both rest url and voucherForm must then be reset
       */
      if (this.loaded) {
        this.listService.restURL = '/voucher/vouchers';
        this.voucherListForm.reset();
      }

      /** If page was opened from a lot, then it must have a navigation back icon and be connected to the elment that called it */
      if (params['lot'] !== 'all') {
        const lot = atob(params['lot']);
        this.listService.backToList = true;
        this.listService.listRouting = '/voucher/lot';
        this.searchType = 'searchLot';
        this.searchTsCreated = lot;
        this.dataFilter['lotCode'] = lot;
      } else {
        this.setValidatedStatusDefault();
      }

      await this.loadElements( 'onInit' );
      this.typeUser = this.utils.getTypeUser();
      this.isSoluzione1 = this.utils.isSol1(this.typeUser);
      
      await this.getCompanies();
      await this.getAllChannel();
      await this.getAllCodetype();
      await this.getLots();
      await this.getAllVoucherStatus();

      this.loaded = true;
      swal2.close();
    });
  }

  async pageChange(page) {
    this.listService.page = page;
    this.logger.log( 'page' , page , 200 )
    await this.loadElements( 'fn pageChange', true );
  }

  changeStatus() {
    if (this.UpOrDown === true) {
      this.UpOrDown = false;
      console.log('VIEE', this.UpOrDown);
    } else {
      this.UpOrDown = true;
    }
  }

  // FILTRI
  async filterSearch() {
    this.dataFilter = this.voucherListForm.value;

    if ( this.voucherListForm.value.searchFor === 'searchDate') {
      // localStorage.removeItem('tsLot');
      this.logger.log( 'date filter' , this.voucherListForm.value.dateTo , 200 )
      if ( this.voucherListForm.value.dateFrom !== null && this.voucherListForm.value.dateFrom !== undefined) {
        this.dataFilter['dateFrom'] = this.utils.convertDateToISO(this.voucherListForm.value.dateFrom) + ' 00:00:00';
      } else {
        delete this.dataFilter['dateFrom']
      }

      if ( this.voucherListForm.value.dateTo !== null && this.voucherListForm.value.dateTo !== undefined) {
        this.dataFilter['dateTo'] = this.utils.convertDateToISO(this.voucherListForm.value.dateTo) + ' 23:59:59';
      } else {
        delete this.dataFilter['dateTo']
      }

      this.dataFilter['emitChannel'] = this.voucherListForm.value.emitChannel;
      this.dataFilter['codeType'] = this.voucherListForm.value.codeType;
      this.dataFilter['status'] = this.voucherListForm.value.status;
    } else if (this.voucherListForm.value.searchFor === 'searchLot') {
      this.dataFilter['lotCode'] = this.voucherListForm.value.lotCode;
    } else if ( this.voucherListForm.value.searchFor !== 'searchLot' ) {
      if ( this.voucherListForm.value.channel !== null || this.voucherListForm.value.channel !== undefined) {
        this.searchChannel = this.voucherListForm.value.channel
      }
      if ( this.voucherListForm.value.codeType !== null || this.voucherListForm.value.codeType !== undefined) {
        this.searchCodetype = this.voucherListForm.value.codeType
      }
      if ( this.voucherListForm.value.status !== null || this.voucherListForm.value.status !== undefined) {
        this.searchStatus = this.voucherListForm.value.status
      }
    }

    this.listService.visibleFilter = true;
    await this.loadElements('fn filterSearch', true);
  }

  async filterReset() {
    this.voucherListForm.value.idCompany = '';
    this.voucherListForm.value.dateFrom = ''
    this.voucherListForm.value.dateTo = ''
    this.voucherListForm.value.searchFor = ''
    this.voucherListForm.reset();
    this.dataFilter = [];
    this.qParams = null
    this.searchChannel = null
    this.searchCodetype = null
    this.searchStatus = 'VALIDATED'
    this.listService.visibleFilter = false;

    this.setValidatedStatusDefault();
    await this.loadElements(null, true);
  }

  getTypeName(id) {
    for (let type of this.typologyList) {
      if (type.idTicketType === id) {
        return type.name;
      }
    }
  }

  // SERVIZI SELECT
  getTypologies() {
    this.listService.getListSelect(this.urlVouchersListPath).subscribe((response) => {
      this.typologyList = response.results;
      this.logger.log('Lista tipologie', this.typologyList, 300);
      // console.log('LISTA commesse: ',response.results);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getCompanyName(id) {
    for (let company of this.companiesList) {
      if (company.idCompany === id) {
        return company.legalBusinessName;
      }
    }
  }

  getCompanies(): Promise<void> {
    return new Promise<void> ((res, rej) => {
      this.listService.getListSelect(this.urlCompaniesPath).subscribe((response) => {
        this.companiesList = response.results;
        this.logger.log('Lista Aziende', this.typologyList, 300);
        res();
      }, (error) => {
        this.logger.log('Error', error, 200);
        rej();
      }
      );
    });
  }

  getFareName(id) {
    for (let fare of this.fareList) {
      if (fare.idTicketFare === id) {
        return fare.name
      }
    }
  }

  getFares() {
    this.listService.getListSelect(this.urlFaresPath).subscribe((response) => {
      this.fareList = response.results;
      this.logger.log('Lista Tariffe', this.fareList, 300);
      // console.log('LISTA commesse: ',response.results);
    },
      (error) => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  findString(string) {
    return this.utils.getStringChannel(string)
  }

  getLots(): Promise<void> {
    return new Promise<void> ((res, rej) => {
      this.listService.getListSelect(this.urlLotsPath).subscribe((response) => {
        this.lotList = response.results;
        this.logger.log('Lista Lotti', this.lotList, 300);
        res();
      }, (error) => {
        this.logger.log('Error', error, 200);
        rej();
      });
    });
  }

  selectUnique( arrayList ) {
    let arrayUnique = new Array()
    for ( let element of arrayList ) {
      if ( arrayUnique.find( x => x === element.lotCode ) === undefined ) {
        arrayUnique.push( element.lotCode )
      }
    }
    return arrayUnique
  }

  // SERVIZIO LISTA ELEMENTI
  loadElements( bckTrace = null, showLoader = false ): Promise<void> {
    if (showLoader) {
      this.showLoader();
    }

    // azzero la lista
    this.dataList = [];
    // controllo i filtri usati
    this.logger.log('dataFilter - loadElements', this.dataFilter , 200);

    if ( bckTrace !== null ) {
      this.logger.log( 'bckTrace - loadElements' , bckTrace , 200 )
    }

    return new Promise<void> ((res, rej) => {
      this.listService.getListFilter(this.dataFilter).subscribe(
        (response) => {
          this.dataList = []
          this.listService.totalRows = response.total;
          this.dataList = response.results
          
          if (showLoader) {
            swal2.close();
          }

          res();
        },
        (error) => {
          this.logger.log('Error', error, 200);
          this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
          rej();
        }
      );
    });
  }

  selectRow(dataElement) {
    console.log( 'selectedRow' , dataElement)
    swal2.fire({
      title: 'Dettaglio Voucher id:' + dataElement.id,
      html:
        'Descrizione: ' + dataElement.description + '<br>' +
        'Code: ' + dataElement.code +
        'CodeType:' + dataElement.codeType + '<br>' +
        'Stato: ' + dataElement.status + '<br>' +
        'Canale: ' + dataElement.emitChannel + '<br>' +
        'Valido da' + dataElement.validFrom + ' a ' + dataElement.validTo,
      icon: 'info',
      customClass: 'text-align-left',
      showCancelButton: false,
      confirmButtonText: 'Torna alla lista',
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
    this.details = dataElement
    this.logger.log( 'this.details' , this.details , 200 )

  }

  newTicketList() {
    this.listService.backToList = true;
    this.router.navigate(['/voucher/new' , '2']);
  }

  deleteTicketList(idList) {
    let mySelf = this;

    swal2.fire({
      title: 'Eliminazione listino',
      text: 'Sei sicuro di voler eliminare il listino?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sì, elimina',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,

    }).then(function (ev: SweetAlertResult) {
      if (ev.value) {
        mySelf.deleteElement(idList);
      }
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        swal2.close();
      }
    })

  }

  duplicate(data) {

    let array: any[] = new Array();
    array = this.utils.getDeatilsToDuplicateTicketList(data.ticketChannels);

    let str =  data.name;
    let n = str.includes('Copia(');
    if (n === true) {
      const res = str.substring(6, 7);

      if (res === ' ') {
        const dataName = str.substring(8);
        data.name = 'Copia(1)' + dataName
      } else {
        let number = +res;
        number = number + 1

        const dataName = str.substring(8);

        data.name = 'Copia(' + number + ')' + dataName;
      }

    } else if (n === false) {
      data.name = 'Copia( )' + data.name;
    }

    data.idTicketPricelist = null;
    data.ticketChannels = array;
    this.listService.newObject(data, this.serviceToCallPath).subscribe(
      async (response) => {
          if (response.outcome.success === true) {
            this.router.navigate(['/tickets/list']);
            swal2.fire('Il Listino  è stato duplicato', '', 'success');
            await this.loadElements( 'fn duplicate - if', true );
          } else {
            this.errorMessage =  response.outcome.code +  response.outcome.description
            swal2.fire('Attenzione', this.errorMessage, 'warning');
          }
      },
      (error) => {
          this.logger.log('Error', error, 200);
          this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
          swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
      }
  );
  }

  getButtonCircle(statusNumber) {
    let buttonColor;
    if (statusNumber === '1') {
   return   buttonColor =  'btn btn-primary btn-circle'
    }
    if (statusNumber === '0') {
      return   buttonColor =  'btn btn-danger btn-circle'
    }
  }

  getStatusDescription(statusNumber) {
    let buttonColor;
    if (statusNumber === '1') {
   return   buttonColor =  'Attivo'
    }
    if (statusNumber === '2') {
      return   buttonColor =  'Non Attivo'
    }
  }

  deleteElement(idElement) {
    this.urlDeletePath =  '/ticket/pricelist/' + idElement;

    swal2.fire({title: 'Eliminazione in corso...', didOpen: function () {swal2.showLoading()}});

    this.logger.log('ID DELETE:', idElement, 200);

    this.listService.delete(this.urlDeletePath).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.logger.log('Response value', response.value, 200);
          swal2.fire('Il Listino è stato eliminato', '', 'success').then(async (ev: SweetAlertResult) => {
            if (ev.value) {
              await this.loadElements( 'fn delete element - if', true );
            }
          });
        } else {
          this.errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.Riprova più tardi', 'error');
      }
    );
  }

  onChangeLotDescription(event , selectValue) {
    this.lotDescriptionName = selectValue;
  }

  onChangeLot(event, selectValue) {
    this.logger.log( 'onChangeLot' , selectValue , 200 )
    this.dataFilter['lotCode'] = selectValue;
  }

  onChangeSearchType( event , selectValue) {
    this.logger.log( 'onChangeSearchType' , selectValue , 200 )
    if ( event.target.value === 'searchDate') {
      this.searchForDate = true
      this.searchForLot = false
    } else if ( event.target.value === 'searchLot' ) {
      this.searchForDate = false
      this.searchForLot = true
    }
    this.dataFilter = [];
  }

  getPageFromArray( page , array = null ) {
    this.logger.log( 'visible filter' , this.listService.visibleFilter , 200 )
    page--
    if ( array === null ) {
      array = this.dataList
    }

    if ( this.dataList.length === this.showList.length ) {
      // this.listService.totalRows = this.dataList.length
      this.logger.log( 'check grandezze ' , 'grandezze UGUALI' + this.dataList.length , 200 )
    } else if ( this.listService.totalRows !== this.showList.length && this.listService.visibleFilter ) {
      // this.listService.totalRows = this.showList.length
      this.logger.log( 'check grnadezze ' , 'grandezze DIVERSE' + this.showList.length , 200 )
    }
    // this.listService.totalRows = this.showList.length
    this.logger.log( 'array.length ' , array.length , 200 )
    this.logger.log( 'showList.length ' , this.showList.length , 200 )
    this.logger.log( 'dataList.length ' , this.dataList.length , 200 )
    return array.slice(page * 15, (page + 1) * 15)
  }

  getAllChannel(): Promise<void> {
    return new Promise<void> ((res, rej) => {
      this.listService.getListSelect(this.urlChannelsPaths).subscribe((response) => {
        this.channelList = response.results;
        this.logger.log('Lista Channels', this.channelList, 200);
        res();
      }, (error) => {
        this.logger.log('Error', error, 200);
        rej();
      });
    });
  }

  getAllCodetype(): Promise<void> {
    return new Promise<void> ((res, rej) => {
      this.listService.getListSelect(this.urlCodetypesPaths).subscribe((response) => {
        this.codetypeList = response.results;
        this.logger.log('Lista Channels', this.codetypeList, 200);
        res();
      }, (error) => {
        this.logger.log('Error', error, 200);
        rej();
      });
    });
  }

  getAllVoucherStatus(): Promise<void> {
    return new Promise<void> ((res, rej) => {
      this.listService.getListSelect(this.urlVoucherStatusPaths).subscribe(response => {
        this.voucherStatusList = response.results;
        this.logger.log('Lista stati voucher', this.voucherStatusList, 200);
        res();
      }, (error) => {
        this.logger.log('Error', error, 200);
        rej();
      });
    });
  }

  openModal(template: TemplateRef<any>) {
    // this.optionSelected = null;
    // this.viewSubOption = false;
    this.modalRef = this.modalService.show(template);
  }

  close() {
    this.modalRef.hide()
  }

  viewDetailVoucher(data, template: TemplateRef<any>) {
    // this.getDetailsTicket(data);
    this.details = data
    this.logger.log( 'this.details' , this.details , 200 )
    this.openModal(template)
  }

  sortArrayByDate( array ) {
    array.sort((a, b) => {
      let d1 = new Date(a.tsCreated)
      let d2 = new Date(b.tsCreated)
      return d2.getTime() - d1.getTime()
    })
  }

  goToDetailTicket( event ) {
    const data = { codeVoucher : event };
    sessionStorage.setItem('dataFilter', JSON.stringify(data) );
    this.router.navigate(['/statistics/vanalysis/']);
  }

  getCsv() {
    let finalQueryString;

    const queryString = this.utils.getQueryWithFilters(this.dataFilter)
    if (queryString === '' || queryString === null) {
      console.log('entra qui string vuota');
      finalQueryString = this.urlVouchersListCSVPath;
    } else {
      finalQueryString = this.urlVouchersListCSVPath + '?' + queryString;
    }

    this.listService.getCsv(finalQueryString).subscribe(
      response => {
        if (response) {
          const blob = new Blob([response], { type: 'text/csv' });
          const ieEDGE = navigator.userAgent.match(/Edge/g);
          const ie = navigator.userAgent.match(/.NET/g); // IE 11+
          const oldIE = navigator.userAgent.match(/MSIE/g);

          let fileName = ''
          const currentDate = new Date()
          const day = currentDate.getDate()
          const month = currentDate.getMonth() + 1
          const year = currentDate.getFullYear()
          fileName = day + '/' + month + '/' + year + '_voucher_list.csv';

          if (ie || oldIE || ieEDGE) {
            console.log(' EDGE !');
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          } else {
            console.log(' NON E\' EDGE !');
            // var url= window.URL.createObjectURL(blob);
            // window.open(url, 'test.csv');
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }

          swal2.fire('Il csv è stato scaricato', '', 'success');
        } else {
          // response.outcome.code + response.outcome.description;
          swal2.fire('Attenzione', this.errorMessage, 'warning');
        }
      },
      error => {
        this.logger.log('Error', error, 200);
        this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
        swal2.fire(
          'Errore',
          'Spiacente, si è verificato un errore tecnico.',
          'error'
        );
      }
    );
  }

  private setValidatedStatusDefault(): void {
    this.voucherListForm.value.status = 'VALIDATED';
    this.dataFilter = this.voucherListForm.value;
  }

  private showLoader(): void {
    swal2.fire({
      title: "Caricamento in corso...",
      didOpen: function() {
        swal2.showLoading();
      }
    });
  }

}
