import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import swal2 from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-users-manage',
  templateUrl: './users-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class UsersManageComponent implements OnInit {
  // testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/user';
  urlSpecificUserPath = '/user/';
  urlTypesPath = '/user/types';
  urlRolesPath = '/user/roles';
  urlResalesPath = '/resale/resales';
  urlCompaniesPath = '/company/companies';

  varSeller;
  varReviewer;
  varObliterator;
  varInternalReseller;

  stringUser;
  // form html
  @ViewChild('formUsers')
  usersForm: NgForm;
  // oggetto contenente dati component
  userData: any;
  roleList: any[] = new Array();
  roleFilterList: any[] = new Array();
  typeList: any[] = new Array();
  resaleList: any[] = new Array();
  companyList: any[] = new Array();
  filterFilterRole: any[] = new Array();

  visibleRole;
  visibleResale;
  visibleCompanyName;
  checkboxVisible;
  disabled;
  editMobileView;

  idUser;

  errorMessage;

  typeUser;
  isCompany;
  isResales;
  isSol1;

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    this.typeUser = this.utils.getTypeUser();
    this.isCompany = this.utils.isComp(this.typeUser);
    this.isResales = this.utils.isRes(this.typeUser);
    this.isSol1 = this.utils.isSol1(this.typeUser);

    // Se creo nuovo utente
    if (route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuovo';
      this.kindManage = 1;
    } else {
      // se modifico utente
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/users/list']);
        return;
      }

      this.editMobileView = false;
      this.idUser = localStorage.getItem('dataPassed');
      this.disabled = true;
      this.loadSpecificUser();
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/users/list';
    console.log(this.listService);
    this.getRoles();
    this.getResales();
    this.getTypology();
    this.getCompanies();

    this.visibleRole = false;
    this.visibleResale = false;
    this.visibleCompanyName = false;
    this.checkboxVisible = false;
  }

  // TRADUZIONE RUOLI E TIPI
  translateTypeUser(typeUser) {
    return this.utils.translateTypeUser(typeUser);
  }

  translateCodeRole(codeRole) {
    return this.utils.translateCodeRole(codeRole);
  }

  translateCodeRoleDiff(typeUser) {
    return this.utils.translateCodeRoleDiff(typeUser);
  }

  getSkillUser() {
    const arrayChannel: any[] = new Array();
    if (this.usersForm.value.checkSeller === true) {
      arrayChannel.push('1');
    }
    if (this.usersForm.value.checkReviewer === true) {
      arrayChannel.push('2');
    }
    if (this.usersForm.value.checkObliterator === true) {
      arrayChannel.push('3');
    }

    if (this.usersForm.value.checkInternalSeller === true) {
      arrayChannel.push('4');
    }

    this.stringUser = arrayChannel.toString();

    console.log('vedi array', arrayChannel.toString());
  }

  // SERVIZI SELECT
  getTypology() {
    this.listService.getListSelect(this.urlTypesPath).subscribe(
      response => {
        console.log('UTENTE BOOL', this.isCompany);
        if (this.isCompany === true) {
          for (let userType of response.results) {
            console.log('UTENTE', userType);
            if (userType.typeUser === 'COMPANY') {
              this.typeList.push(userType);
            }
            if (userType.typeUser === 'RESALE') {
              //  this.typeList.push(userType)
            }
          }
        } else if (this.isResales === true) {
          for (let userType of response.results) {
            console.log('RIVENDITA', userType);

            if (userType.typeUser === 'RESALE') {
              this.typeList.push(userType);
            }
          }
        } else {
          this.typeList = response.results;
        }

        this.logger.log('Lista Tipologie', this.typeList, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getCompanies() {
    this.listService.getListSelect(this.urlCompaniesPath).subscribe(
      response => {
        this.companyList = response.results;
        this.logger.log('Lista Aziende', this.typeList, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getRoles() {
    this.listService.getListSelect(this.urlRolesPath).subscribe(
      response => {
        this.roleList = response.results;
        this.logger.log('Lista Ruoli', this.roleList, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  getResales() {
    this.listService.getListSelect(this.urlResalesPath).subscribe(
      response => {
        this.resaleList = response.results;
        this.logger.log('Lista Rivendite', this.resaleList, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  onchangeMobileOperator(mobile) {
    console.log(mobile);

    this.checkboxVisible = false;
    if (mobile === 'COMPANY_OPERATOR') {
      this.checkboxVisible = true;
    }
  }

  onChangeType(type) {
    this.roleFilterList = [];
    this.visibleResale = false;
    this.checkboxVisible = false;
    this.visibleCompanyName = false;

    for (let r of this.roleList) {
      switch (type) {
        case 'COMPANY': {
          if (r.codeRole.indexOf(type) >= 0) {
            if (r.roleUser === 'OPERATOR') {
              this.roleFilterList.push(r);
            } else if (r.roleUser === 'ADMINISTRATOR') {
              this.roleFilterList.push(r);
            }
          }
          this.visibleCompanyName = true;
          this.visibleRole = true;
          break;
        }
        case 'RESALE': {
          if (r.codeRole.indexOf(type) >= 0) {
            if (r.roleUser === 'SELLER') {
              this.roleFilterList.push(r);
            }
          }
          this.visibleRole = true;
          this.visibleResale = true;
          break;
        }
        case 'SOLUZIONE1': {
          if (r.codeRole.indexOf(type) >= 0) {
            this.roleFilterList.push(r);
          }
          this.visibleRole = true;
          break;
        }
        default: {
          this.visibleRole = false;
          break;
        }
      }
    }
    console.log('FILTER_RESULT', this.roleFilterList);
  }

  loadSpecificUser() {
    this.listService
      .getObject(this.urlSpecificUserPath + this.idUser)
      .subscribe(
        response => {
          //  Verificare outcome

          if (response.roleUser === 'COMPANY_OPERATOR') {
            this.editMobileView = true;
          }

          this.utils.checkResponseTypeUserManage(
            response.typeUser,
            response.roleUser
          );

          this.onChangeType(response.typeUser);

          this.userData = response;

          if (this.editMobileView === true) {
            //  varSeller;
            //    varReviewer;
            //   varObliterator;
            this.varSeller = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '1'
            );
            this.varReviewer = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '2'
            );
            this.varObliterator = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '3'
            );
            this.varInternalReseller = this.utils.checkRoleMobileUser(
              this.userData.roleUserMobile,
              '4'
            );
          }

          console.log('DATA USER', this.userData);

          this.disabled = true;

          if (this.userData !== null) {
            this.visibleRole = true;
          } else {
            this.visibleRole = false;
          }

          this.logger.log('Response:', response, 300);
          localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        },
        error => {
          this.logger.log('Error', error, 200);
        }
      );
  }

  onSubmit(): void {
    swal2.fire({
      title: 'Operazione in corso...',
      didOpen: function() {
        swal2.showLoading();
      }
    });

    if (this.kindManage) {
      // TEST
      if ((this.checkboxVisible = true)) {
        this.getSkillUser();
      }

      console.log(this.stringUser);
      this.userData = this.usersForm.value;
      this.userData.roleUserMobile = this.stringUser;

      console.log('UTENTE OGGETTO PER MOBILE', this.userData);
      this.listService
        .newObject(this.userData, this.serviceToCallPath)
        .subscribe(
          response => {
            if (response.outcome.success === true) {
              this.authService.invite(response.idUser).subscribe(
                resp => {
                  if (resp.outcome.success === true) {
                    swal2.fire('L\'utente è stato creato', '', 'success');
                    this.router.navigate(['/users/list']);
                  } else {
                    this.errorMessage =
                      resp.outcome.code + resp.outcome.description;
                    swal2.fire('Attenzione', this.errorMessage, 'warning');
                  }
                },
                error => {
                  this.logger.log('Error', error, 200);
                  this.errorMessage =
                    'Spiacente, si è verificato un errore tecnico.';
                  swal2.fire(
                    'Errore',
                    'Spiacente, si è verificato un errore tecnico.',
                    'error'
                  );
                }
              );

              swal2.fire('Invito mandato!', '', 'success');
              this.router.navigate(['/users/list']);
            } else {
              this.errorMessage =
                response.outcome.code + response.outcome.description;
              swal2.fire('Attenzione', this.errorMessage, 'warning');
            }
          },
          error => {
            this.logger.log('Error', error, 200);
            this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
            swal2.fire(
              'Errore',
              'Spiacente, si è verificato un errore tecnico.',
              'error'
            );
          }
        );
    } else {
      if ((this.editMobileView = true)) {
        this.getSkillUser();
      }

      this.userData = this.usersForm.value;
      this.userData.roleUserMobile = this.stringUser;

      this.listService.edit(this.userData, this.serviceToCallPath).subscribe(
        response => {
          if (response.outcome.success === true) {
            swal2.fire('L\'utente è stato modificato', '', 'success');
            this.router.navigate(['/users/list']);
          } else {
            this.errorMessage =
              response.outcome.code + response.outcome.description;
            swal2.fire('Attenzione', this.errorMessage, 'warning');
          }
        },
        error => {
          this.logger.log('Error', error, 200);
          this.errorMessage = 'Spiacente, si è verificato un errore tecnico.';
          swal2.fire(
            'Errore',
            'Spiacente, si è verificato un errore tecnico.',
            'error'
          );
        }
      );
    }
  }

  back() {
    this.router.navigate(['/users/list']);
  }
}
