import { Routes } from '@angular/router';
import { MainViewComponent } from './views/main-view/main-view.component';
import { BlankComponent } from './common/layouts/blank/blank.component';
import { BasicComponent } from './common/layouts/basic/basic.component';
import { LoginComponent } from './views/auth/login/login.component';
import { LogoutComponent } from './views/auth/logout/logout.component';
import { ForgotPwdComponent } from './views/auth/forgot-pwd/forgot-pwd.component';
import { ChangePwdComponent } from './views/auth/change-pwd/change-pwd.component';
import { OrdersListComponent } from './views/orders/orders-list/orders-list.component';
import { OrderEditComponent } from './views/orders/order-edit/order-edit.component';
import { OrderNewComponent } from './views/orders/order-new/order-new.component';
import { UsersListComponent } from './views/users/users-list/users-list.component';
import { UsersManageComponent } from './views/users/users-manage/users-manage.component';
import { DocumentsListComponent } from './views/documents/documents-list/documents-list.component';

import { ActivateComponent } from './views/auth/activate/activate.component';

import { DocumentsSupplierComponent} from './views/documents/documents-supplier/documents-supplier.component'
import { DocumentsChecklistDetailsComponent } from './views/documents/documents-checklist-details/documents-checklist-details.component'
import { DocumentsNewComponent} from './views/documents/documents-new/documents-new.component';
import { ActivityListComponent }from './views/activities/activity-list/activity-list.component';
import { LinesListComponent } from './views/lines/lines-list/lines-list.component';
import { TripsListComponent } from './views/trips/trips-list/trips-list.component';
import { WalletListComponent } from './views/wallet/wallet-list/wallet-list.component';
import { WalletRechargeComponent } from './views/wallet/wallet-recharge/wallet-recharge.component';
import { UserProfileEditComponent } from './views/user-profile/user-profile-edit/user-profile-edit.component';
import { PrintersListComponent } from './views/printers/printers-list/printers-list.component';
import { PrintersManageComponent } from './views/printers/printers-manage/printers-manage.component';
import { SalesNewComponent } from './views/sales/sales-new/sales-new.component';
import { SalesListComponent } from './views/sales/sales-list/sales-list.component';
import { VehiclesListComponent } from './views/vehicles/vehicles-list/vehicles-list.component';
import { VehiclesManageComponent } from './views/vehicles/vehicles-manage/vehicles-manage.component';
import { ObliteratorsListComponent } from './views/obliterators/obliterators-list/obliterators-list.component';
import { ObliteratorsManageComponent } from './views/obliterators/obliterators-manage/obliterators-manage.component';
import { TicketsTypologyComponent } from './views/tickets/tickets-typology/tickets-typology.component';
import { TicketsFaresComponent } from './views/tickets/tickets-fares/tickets-fares.component';
import { TicketsListComponent } from './views/tickets/tickets-list/tickets-list.component';
import { TicketsTypologyManageComponent } from './views/tickets/tickets-typology-manage/tickets-typology-manage.component';
import { TicketsListManageComponent } from './views/tickets/tickets-list-manage/tickets-list-manage.component';
import { TicketsFaresManageComponent } from './views/tickets/tickets-fares-manage/tickets-fares-manage.component';
import { StatisticsEarningsComponent } from './views/statistics/statistics-earnings/statistics-earnings.component';
import { StatisticsSoldComponent } from './views/statistics/statistics-sold/statistics-sold.component';
import { StatisticsTicketComponent } from './views/statistics/statistics-ticket/statistics-ticket.component';
import { ResalesListComponent } from './views/resales/resales-list/resales-list.component';
import { ResalesManageComponent } from './views/resales/resales-manage/resales-manage.component';
import { CompaniesListComponent } from './views/companies/companies-list/companies-list.component';
import { CompaniesManageComponent } from './views/companies/companies-manage/companies-manage.component';
import { StopsListComponent } from './views/stops/stops-list/stops-list.component';
import { StopTimesComponent } from './views/stop-times/stop-times.component';
import { DateTripsComponent } from './views/date-trips/date-trips.component';
import { ResearchTripLineComponent } from './views/research-trip-line/research-trip-line.component';
import { WalletRechargeListComponent } from './views/wallet-recharge/wallet-recharge-list/wallet-recharge-list.component';
import { WalletRechargeManageComponent } from './views/wallet-recharge/wallet-recharge-manage/wallet-recharge-manage.component';
import { SalesSummaryComponent } from './views/sales/sales-summary/sales-summary.component';
import { CompaniesDetailsComponent } from './views/companies/companies-details/companies-details.component';
import { SubscritionListComponent } from './views/subscription/subscrition-list/subscrition-list.component';
import { SubscriptionTypologyComponent } from './views/subscription/subscription-typology/subscription-typology.component';
import { SubscriptionFareComponent } from './views/subscription/subscription-fare/subscription-fare.component';
import { SubscriptionTypologyManageComponent } from './views/subscription/subscription-typology-manage/subscription-typology-manage.component';
import { SubscriptionFareManageComponent } from './views/subscription/subscription-fare-manage/subscription-fare-manage.component';
import { SubscriptionSellComponent } from './views/subscription/subscription-sell/subscription-sell.component';
import { SubscriptionListManageComponent } from './views/subscription/subscription-list-manage/subscription-list-manage.component';
import { ResalesDetailsComponent } from './views/resales/resales-details/resales-details.component';
import { RecoverPdfComponent } from './views/recover-pdf/recover-pdf.component';
import { StatisticPassengersComponent } from './views/statistics/statistic-passengers/statistic-passengers.component';
import { StatisticsOperatorsComponent } from './views/statistics/statistics-operators/statistics-operators.component';
import { SalesTicketComponent } from './views/sales/sales-ticket/sales-ticket.component';
import { DeskListComponent } from './views/desk/desk-list/desk-list.component';
import { DeskManageComponent } from './views/desk/desk-manage/desk-manage.component';
import { SelectDeskComponent } from './views/auth/select-desk/select-desk.component';
import { TicketPromotionsManageComponent } from './views/tickets/ticket-promotions-manage/ticket-promotions-manage.component';
import { TicketPromotionsComponent } from './views/tickets/ticket-promotions/ticket-promotions.component';
import { SalesSubscriptionComponent } from './views/sales/sales-subscription/sales-subscription.component';
import { BlocksListComponent } from './views/blocks/blocks-list/blocks-list.component';
import { BlocksManageComponent } from './views/blocks/blocks-manage/blocks-manage.component';
import { UsersEcommerceComponent } from './views/users/users-ecommerce/users-ecommerce.component';
import { StatisticsExportComponent } from './views/statistics/statistics-export/statistics-export.component';
import { SalesRecapOperatorComponent } from './views/sales/sales-recap-operator/sales-recap-operator.component';
import { AclManageComponent } from './views/utility/acl/acl-manage.component';
import { AclListComponent } from './views/utility/acl/acl-list.component';
import { StatisticsObliterationComponent } from './views/statistics/statistics-obliteration/statistics-obliteration.component';
// import {DocumentsViewerComponent} from './views/documents/documents-viewer/documents-viewer.component'
import { VoucherListComponent } from './views/voucher/voucher-list/voucher-list.component';
import { VoucherLottiComponent } from './views/voucher/voucher-lotti/voucher-lotti.component';
import { VoucherNewComponent } from './views/voucher/voucher-new/voucher-new.component';
import { StatisticsVoucherComponent } from './views/statistics/statistics-voucher/statistics-voucher.component';
import { TicketCategoryComponent } from './views/tickets/ticket-category/ticket-category.component';
import { ZonesListComponent } from './views/zones/zones-list/zones-list.component';
import { ZonesManageComponent } from './views/zones/zones-manage/zones-manage.component';
import { ImporterComponent } from './views/utility/importer/importer.component';
import { FilesComponent } from './views/utility/files/files.component';
import { ParamsListComponent } from './views/utility/params/params-list.component';
import { ParamsManageComponent } from './views/utility/params/params-manage.component';
import { KeysListComponent } from './views/utility/keys/keys-list.component';
import { KeysManageComponent } from './views/utility/keys/keys-manage.component';
import { VoucherChannelsListComponent } from './views/utility/voucher-channels/voucher-channels-list.component';
import { SalesChannelsListComponent } from './views/sales-channels/sales-channels-list/sales-channels-list.component';
import { SalesChannelsManageComponent } from './views/sales-channels/sales-channels-manage/sales-channels-manage.component';
import { LinesManageComponent } from './views/lines/lines-manage/lines-manage.component';
import { TicketCategoryManageComponent } from './views/tickets/ticket-category-manage/ticket-category-manage.component';
import { UsersPassesComponent } from './views/users/users-passes/users-passes-list/users-passes-list.component';
import { UsersValidationsComponent } from './views/users/users-validations/users-validations-list/users-validations-list.component';
import { UsersPassesManageComponent } from './views/users/users-passes/users-passes-manage/users-passes-manage.component';
import { UsersValidationsManageComponent } from './views/users/users-validations/users-validations-manage/users-validations-manage.component';

export const ROUTES: Routes = [
  //  Main redirect
  {path: '', redirectTo: 'user-profile/myProfile', pathMatch: 'full'},
  //  App views
  {
    path: '', component: BlankComponent,
    children: [
      { path: 'test1', component: OrderEditComponent },
      { path: 'test1/:id', component: OrderEditComponent },
      { path: 'test2', component: OrdersListComponent },

    ]
  },
  {
    path: '', component: BasicComponent,
    children: [
      { path: 'mainView', component: MainViewComponent },
      { path: 'orders/list', component: OrdersListComponent },
      { path: 'orders/new', component: OrderNewComponent },
      { path: 'orders/edit/:id', component: OrderEditComponent },
      { path: 'users/list', component: UsersListComponent },
      { path: 'users/ecommerce', component: UsersEcommerceComponent },
      { path: 'users/new', component: UsersManageComponent },
      { path: 'users/edit', component: UsersManageComponent },
      { path: 'users/passes', component: UsersPassesComponent },
      { path: 'users/passes/new', component: UsersPassesManageComponent },
      { path: 'users/passes/edit', component: UsersPassesManageComponent },
      { path: 'users/validations', component: UsersValidationsComponent },
      { path: 'users/validations/new', component: UsersValidationsManageComponent },
      { path: 'change-pwd', component: ChangePwdComponent },
      { path: 'activities/activity', component: ActivityListComponent},
      { path: 'lines/list', component: LinesListComponent},
      { path: 'lines/new', component: LinesManageComponent},
      { path: 'trips/list', component: TripsListComponent},
      { path: 'wallet/list', component: WalletListComponent},
      { path: 'wallet/recharge/list', component: WalletRechargeListComponent},
      { path: 'wallet/recharge/edit', component: WalletRechargeManageComponent},
      { path: 'wallet/recharge/new', component: WalletRechargeManageComponent},
      { path: 'wallet/recharge', component: WalletRechargeComponent},
      { path: 'user-profile/myProfile', component: UserProfileEditComponent},
      { path: 'printers/list', component: PrintersListComponent},
      { path: 'printers/new', component: PrintersManageComponent},
      { path: 'printers/edit', component: PrintersManageComponent},
      { path: 'newSale', component: SalesNewComponent},
      { path: 'summarySale', component: SalesSummaryComponent},
      { path: 'sales/recap/operator', component: SalesRecapOperatorComponent},
      { path: 'sales/list', component: SalesListComponent},
      { path: 'vehicles/list', component: VehiclesListComponent},
      { path: 'vehicles/new', component: VehiclesManageComponent},
      { path: 'vehicles/edit', component: VehiclesManageComponent},
      { path: 'obliterators/list', component: ObliteratorsListComponent},
      { path: 'obliterators/new', component: ObliteratorsManageComponent},
      { path: 'obliterators/edit', component: ObliteratorsManageComponent},
      { path: 'tickets/fares', component: TicketsFaresComponent},
      { path: 'tickets/fares/edit', component: TicketsFaresManageComponent},
      { path: 'tickets/fares/new', component: TicketsFaresManageComponent},
      { path: 'tickets/list', component: TicketsListComponent},
      { path: 'voucher/list', component: VoucherListComponent},
      { path: 'voucher/list/:lot', component: VoucherListComponent},
      { path: 'voucher/lot', component: VoucherLottiComponent},
      { path: 'voucher/new/:caller', component: VoucherNewComponent},
      { path: 'voucher/promotion/new', component: TicketPromotionsManageComponent},
      { path: 'voucher/promotion/edit', component: TicketPromotionsManageComponent},
      { path: 'voucher/promotion', component: TicketPromotionsComponent},
      { path: 'tickets/list/new', component: TicketsListManageComponent},
      { path: 'tickets/list/edit', component: TicketsListManageComponent},
      { path: 'tickets/typology', component: TicketsTypologyComponent},
      { path: 'tickets/typology/edit', component: TicketsTypologyManageComponent},
      { path: 'tickets/typology/new', component: TicketsTypologyManageComponent},
      { path: 'statistics/earnings', component: StatisticsEarningsComponent},
      { path: 'statistics/sold', component: StatisticsSoldComponent},
      { path: 'statistics/ticket', component: StatisticsTicketComponent},
      { path: 'statistics/export', component: StatisticsExportComponent},
      { path: 'resales/list', component: ResalesListComponent},
      { path: 'resales/new', component: ResalesManageComponent},
      { path: 'resales/edit', component: ResalesManageComponent},
      { path: 'resales/details', component: ResalesDetailsComponent},
      { path: 'companies/list', component: CompaniesListComponent},
      { path: 'companies/new', component: CompaniesManageComponent},
      { path: 'companies/edit', component: CompaniesManageComponent},
      { path: 'companies/details', component: CompaniesDetailsComponent},
      { path: 'stops/list', component: StopsListComponent},
      { path: 'time/stops', component: StopTimesComponent},
      { path: 'time/trips', component: DateTripsComponent},
      { path: 'research/trips-lines', component: ResearchTripLineComponent},
      { path: 'recover/pdf', component: RecoverPdfComponent},
      { path: 'subscription/list', component: SubscritionListComponent},
      { path: 'subscription/list/new', component: SubscriptionListManageComponent},
      { path: 'subscription/list/edit', component: SubscriptionListManageComponent},
      { path: 'subscription/typology', component: SubscriptionTypologyComponent},
      { path: 'subscription/fares', component: SubscriptionFareComponent},
      { path: 'subscription/typology/edit', component: SubscriptionTypologyManageComponent},
      { path: 'subscription/typology/new', component: SubscriptionTypologyManageComponent},
      { path: 'subscription/fares/edit', component: SubscriptionFareManageComponent},
      { path: 'subscription/fares/new', component: SubscriptionFareManageComponent},
      { path: 'subscription/sell', component: SalesSubscriptionComponent},
      { path: 'statistics/passengers', component: StatisticPassengersComponent},
      { path: 'statistics/operators', component: StatisticsOperatorsComponent},
      { path: 'statistics/obliterations', component: StatisticsObliterationComponent},
      { path: 'sale/ticket', component: SalesTicketComponent},
      { path: 'desk/list', component: DeskListComponent},
      { path: 'desk/edit', component: DeskManageComponent},
      { path: 'desk/new', component: DeskManageComponent},
      { path: 'blocks/list', component: BlocksListComponent},
      { path: 'blocks/new', component: BlocksManageComponent},
      { path: 'blocks/edit', component: BlocksManageComponent},
      { path: 'zones/list', component: ZonesListComponent},
      { path: 'zones/new', component: ZonesManageComponent},
      { path: 'zones/edit', component: ZonesManageComponent},
      { path: 'utility/acl/list', component: AclListComponent},
      { path: 'utility/acl/new', component: AclManageComponent},
      { path: 'utility/acl/edit', component: AclManageComponent},
      { path: 'utility/files/list', component: FilesComponent},
      { path: 'utility/importer', component: ImporterComponent},
      { path: 'utility/params', component: ParamsListComponent},
      { path: 'utility/params/new', component: ParamsManageComponent},
      { path: 'utility/params/edit', component: ParamsManageComponent},
      { path: 'utility/keys', component: KeysListComponent},
      { path: 'utility/keys/new', component: KeysManageComponent},
      { path: 'utility/keys/edit', component: KeysManageComponent},
      { path: 'utility/channel/list', component: VoucherChannelsListComponent },
      { path: 'statistics/vanalysis', component: StatisticsVoucherComponent},
      { path: 'categories', component: TicketCategoryComponent},
      { path: 'categories/new/:categoryType', component: TicketCategoryManageComponent},
      { path: 'categories/edit/:categoryType', component: TicketCategoryManageComponent},
      { path: 'salesChannels', component: SalesChannelsListComponent },
      { path: 'salesChannels/new', component: SalesChannelsManageComponent },
      { path: 'salesChannels/edit', component: SalesChannelsManageComponent }
    ]
  },
  {
    path: '', component: BlankComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'logout/:id', component: LogoutComponent },
      { path: 'forgot-pwd', component: ForgotPwdComponent },
      { path: 'activate/:firstLoginToken', component: ActivateComponent },
      { path: 'choose/desk', component: SelectDeskComponent },

    ]
  },
  //  Handle all other routes
  {path: '**',  redirectTo: 'login', pathMatch: 'full' }
];
