import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserModule} from "@angular/platform-browser";
import { SalesNewComponent } from "./sales-new/sales-new.component";
import { SalesListComponent } from "./sales-list/sales-list.component";
import { SalesConsultationDetailsComponent } from "./sales-details/sales-details.component";
import { QrCodeModule } from 'ng-qrcode';
import { ToastrModule } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SalesSummaryComponent } from './sales-summary/sales-summary.component';
import { SalesTicketComponent } from './sales-ticket/sales-ticket.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SalesSubscriptionComponent } from './sales-subscription/sales-subscription.component';
import { SalesRecapOperatorComponent } from './sales-recap-operator/sales-recap-operator.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,BrowserAnimationsModule,
    ReactiveFormsModule,
    QrCodeModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    BsDropdownDirective
  ],
  bootstrap:[SalesRecapOperatorComponent],
  declarations: [SalesNewComponent,SalesListComponent,SalesConsultationDetailsComponent, SalesSummaryComponent, SalesTicketComponent, SalesSubscriptionComponent, SalesRecapOperatorComponent]
})
export class SalesModule {}