import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { LoggingService } from "../../../services/logging.service";
import { ListService } from "../../../services/list.service";
import swal2 from "sweetalert2";
import { CookieService } from 'ngx-cookie-service';
import { UUID } from "angular2-uuid";
import { UtilsService } from "../../../services/utils.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IConfigValue } from "../../../interfaces/configuration.interface";

const TOLERANCE_BASE = 360;
const TOLERANCE_6_MONTHS = 21600;
const TOLERANCE_MONTHS = 1;

@Component({
  selector: 'app-sales-ticket',
  templateUrl: './sales-ticket.component.html',
  styleUrls: ["../../../app.component.css"]
})
export class SalesTicketComponent implements OnInit {
  
  @ViewChild("formFindTrip") findTripForm: NgForm;

  minDate: Date;
  maxDate: Date;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  daterangepickerModel: Date[];
  datepickerModel: Date;
  inputTextPerson = "";
  groupCategories;
  categories: any[] = new Array();
  passengers: any[] = new Array();
  cart: any[] = new Array();
  searchTripList:boolean;
  tripParametersList:boolean;
  originStops: any[] = new Array();
  destinationStops: any[] = new Array();
  printerList: any[] = new Array();
  arrayPromotionOptional: any[] = new Array();
  arrayPromotionConditional: any[] = new Array();
  pathListStops = '/stop/stops/v2';

  groupCategoryId;
  destinationIdCompany;
  pathListTrips = '/trip/trips/v2';
  pathListTripsOption = '/trip/trips/option';
  pathListTripsPromoTags = '/trip/trips/promotion-tags';
  pathListTripsResume = '/trip/trips/resume'
  pathListCategories = '/category/categories-grouped' // filtro per ticket
  tripsAndata;
  tripsRitorno = null;
  tripsAndataResume;
  tripsRitornoResume = null;
  arrayPromotions = null;
  //tripIdA = -1;
  //tripIdR = -1;
  requestResumeTmp;
  requestResume;
  total;
  isPdfEnabled;
  pathChekPdfAvaiable = '/ticket/pdfenable'
  pathUrlPrinterList = '/printer/printers'
  defaultValuePcUUID;
  defaultIdPrinter;
  serialNumber;
  idCompany;
  pathListInitTicket = '/ticket/v2'
  dataResponseTickets: any[] = new Array();
  errorMessage;
  channel;
  urlCredit = "/transaction/resalescredit/"
  avaiableCredit;
  avaiableTrust;
  destinationA;
  destinationR;
  originA;
  originR;
  isOperator;
  requestToSendAfterPromotion;
  idPromozioneApplicata = 0;
  idPromozioneCondizionale = '0';
  isCorrectCart = false;
  isTicket = false;

  tripParametersData = {};
  tripParametersDataName: TripParamsName = new TripParamsName();

  // parametri scelta
  kindSell;
  kindRound;
  isRoundTrip;

  pdfTemplateList = [];
  printers = [];
  ticketTypes: IConfigValue[];
  roundTripTypes: IConfigValue[];

  constructor( public listService: ListService,  public utils: UtilsService,  private logger: LoggingService,  public cookieService: CookieService, public toastr: ToastrService,   private router: Router) { 
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() );
    this.maxDate.setDate(this.maxDate.getFullYear() + 1);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme }, {dateInputFormat: 'DD-MM-YYYY'}, {rangeInputFormat: 'DD-MM-YYYY'});
  }

  ngOnInit() {
    this.channel = this.utils.getSoldChannel()
    this.ticketTypes = this.utils.manageTicketTypes();
    this.roundTripTypes = this.utils.manageRoundTrip();
    this.isRoundTrip = false
    this.kindSell = this.tripParametersDataName.kindSell = this.ticketTypes[0].id;
    this.kindRound = this.tripParametersDataName.kindRound = this.roundTripTypes[0].id;
    this.searchTripList = false;
    this.tripParametersList = true;
    this.isOperator = this.utils.isOp();
    this.isTicket = this.checkIsTicket();
    this.getStopsFrom();
    this.getPrinterList();
    this.checkEnable();
    this.detectUUID();
    this.idCompany =  localStorage.getItem("idCompany")
    this.destinationIdCompany =  localStorage.getItem("idCompany")
    
    if(this.channel == "3"){
      this.getCredit()
    }

    this.listService.listRouting = "/sale/ticket";
    this.listService.backToList = true;

    this.retrieveTripParametersData();
  }

  retrieveTripParametersData(){
    this.isRoundTripMethod(null, this.roundTripTypes[0].id);

    if (localStorage.getItem("tripParametersData")) {
      this.tripParametersData = JSON.parse(localStorage.getItem("tripParametersData"));
      this.tripParametersDataName = JSON.parse(localStorage.getItem("tripParametersDataName"));
      localStorage.removeItem("tripParametersData");
      localStorage.removeItem("tripParametersDataName");
      this.setKindSell(this.tripParametersDataName.kindSell);
    
      this.requestResumeTmp = this.tripParametersData;
      
      this.chooseTripA(this.tripParametersData['tripIdA'],0);

      //carico categorie
      this.getCategories();
      this.searchTripList = true;
      this.tripParametersList = false;
    }
  }

  getCredit() {
    this.listService.getListSelect(this.urlCredit).subscribe((response) => {
      var credit = response.results;
      this.logger.log("Lista rivenditori", credit, 300);

      for(let c of credit)
      {
        this.avaiableCredit = c.creditAmount
        this.avaiableTrust = c.trust;
      }

   
      //console.log("LISTA commesse: ",response.results);
    },
      (error) => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  getPrinterList() {
    let deskFilter = "";
    this.logger.log("isOperator", this.isOperator, 200);
    this.logger.log("selected desk", this.utils.getSelectedDesk(), 200);
    this.logger.log("role user PL", this.utils.getRoleUser(), 200);
    if (this.isOperator && this.utils.getSelectedDesk() != null) {
      deskFilter = "?idDesk=" + this.utils.getSelectedDesk();
    }
    this.listService.getListSelect(this.pathUrlPrinterList + deskFilter).subscribe(
      response => {
        this.printers = response.results;
        this.printerList = this.printerList.concat( this.pdfTemplateList );
        console.log('printerList', this.printerList, 'response.results', response.results);
        this.retrievePcUUID(this.printerList);
        //console.log("LISTA commesse: ",response.results);
      },
      error => {
        this.logger.log("Error", error, 200);
      },
      () => {
        this.printerList = this.printerList.concat( this.pdfTemplateList );
      }
    );
  }

  retrievePcUUID(printerList) {
    for (let p of printerList) {
      console.log("ID UUID", this.defaultValuePcUUID);
      console.log("ID UUID", p.pcUUID);

      if (p.pcUUID == this.defaultValuePcUUID) {
        this.defaultIdPrinter = p.id;
        this.serialNumber = p.serialNumber;
        console.log("ID PRINT", this.defaultIdPrinter);
      }
    }
  }

  detectUUID() {
    if (
      this.cookieService.get("BU-UUID") == undefined
    ) {
      console.log("Genero UUID macchina");
      let uuid = UUID.UUID();
      this.cookieService.set("BU-UUID", uuid);
    } else {
      console.log(
        "UUID macchina =>",
        this.cookieService.get("BU-UUID")
      );
    }
    this.defaultValuePcUUID = this.cookieService.get(
      "BU-UUID");
  }
  getSerialNumber(id) {

    this.serialNumber = null
    var stringTest = id.split(":")[1];
    var stringtrip = stringTest.substring(1);
    var idPrinter = stringtrip;
    for (let p of this.printerList) {
      if (p.id == idPrinter) {
        this.serialNumber = p.serialNumber;
      }
    }

    console.log("SERIAL NUMBER",  this.serialNumber)
  }

  getPromozione(id) {
    this.idPromozioneApplicata = id//.split(":")[1].substring(1);
  }
  
  getPromozioneCondizionale(id) {
    this.idPromozioneCondizionale = id//.split(":")[1].substring(1);
  }

  checkEnable(){
    this.listService.getListSelect(this.pathChekPdfAvaiable).subscribe( response => {
      this.isPdfEnabled =  response.outcome.success;
      this.pdfTemplateList = response.results.map( item => {
        item['id'] = item.template;
        delete item.template;
        return item;
      });
      console.log("RISPOSTA PDF", this.isPdfEnabled, 'pdfTemplateList' , this.pdfTemplateList)
    },
    error => {
      this.logger.log("Error", error, 200);
    }, 
    () => {
      this.printerList = this.pdfTemplateList.concat(this.printers);
    });
  }

  setKindSell(kind) {
    this.kindSell = kind;
    this.isRoundTrip = false;
  }

  isHOHO() {
    return this.kindSell == 'H';
  }

  checkIsTicket() {
    return this.kindSell == 'T';
  }

  isRoundTripMethod(event, string){
    if(string == "ONE"){
      this.isRoundTrip = false
      delete this.tripParametersData['tripIdR'];
    }else if(string == "ROUNDTRIP"){
      this.isRoundTrip = true
    }else if(string == "ROUNDTRIPOPEN"){
      this.isRoundTrip = false
      delete this.tripParametersData['tripIdR'];
    }else{
      this.isRoundTrip = false
      delete this.tripParametersData['tripIdR'];
    }
    //delete this.tripParametersData['tripIdA'];
    
    this.searchTripList = false;
    this.tripParametersList = true;
    this.kindRound = string
    console.log("ANDATA e Ritorno? " + this.isRoundTrip )
  }

  preventClose(event: MouseEvent) {
    event.preventDefault();
    let div: HTMLElement = document.getElementById('name') as HTMLElement;
    div.click();
  }

  saveValueInput(pos, val) {
    console.log("pos:" + pos + " & val:" + val.target.value);
    let valToSave = val.target.value;
    if (valToSave > this.utils.manageMaxTicket()) valToSave = this.utils.manageMaxTicket();
    if (valToSave < 0) valToSave = 0;
    this.passengers[pos]['total'] = valToSave;
    this.changeTextInputValue();
  }

  changeTextInputValue() {
    this.inputTextPerson = "";
    let count = 0;
    Object.keys(this.passengers).forEach((key) => {
      console.log(this.categories[key])
      if (count !== 0)
      this.inputTextPerson = this.inputTextPerson + ","  + this.passengers[key]['total'] + " " + this.categories[key]['name'];
      else
       this.inputTextPerson = this.inputTextPerson + this.passengers[key]['total'] + " " + this.categories[key]['name'];
      count ++;
    });

    this.tripParametersData['passengers'] = this.passengers;
    //CARICA PROMOZIONI OPZIONALI DISPONIBILI 2021VR
    this.getPromotion(this.tripParametersData);
    this.getPromotionCond(this.tripParametersData);
  }

  minusValueInput(pos) {
    if (this.passengers[pos]['total'] > 0) {
      this.passengers[pos]['total'] = this.passengers[pos]['total'] - 1;
    }
    this.changeTextInputValue();
  }
  plusValueInput(pos) {
    if (this.passengers[pos]['total'] < 10) {
      this.passengers[pos]['total'] = this.passengers[pos]['total'] + 1;
    }
    this.changeTextInputValue();
  }
 
  createPassengers() {
    this.passengers = [];
    let listPassengers = this.groupCategories[this.groupCategoryId].categories;
    Object.keys(listPassengers).forEach((key) => {
      this.passengers.push({categoryId: listPassengers[key]['categoryId'], total: 0});
    });
    console.log(this.passengers);
  }

  onChangeGroupCategory(index) {
    this.groupCategoryId = index;
    this.categories = this.groupCategories[this.groupCategoryId].categories;
    this.createPassengers();
  }

  searchTrip(){
    this.searchTripList = true;
    this.tripParametersList = false;
  }
  

  // Tutte le fermate da
  getStopsFrom(): void {
    this.listService.getListSelect(this.pathListStops)
    .subscribe((response) => {
      this.originStops = response.results;
      this.logger.log("Lista fermate partenza", this.originStops, 300);
      },
      (error) => {
          this.logger.log("Error", error, 200);
      }
    );
  }

  onChangeStop() {
    this.tripParametersDataName.origin = this.findTripForm.value.origin.stopName;
    this.getStopsTo(this.findTripForm.value.origin.stopId);
  }

  onChangeDestinationStop() {
    this.tripParametersDataName.destination = this.findTripForm.value.destination.stopName;
    this.destinationIdCompany = this.findTripForm.value.destination.idCompany;
    //carico area scelta promo condizionali e visualizzo picker per categorie e passeggeri
    this.choseTripList();
  }

  //2021VR
  onChangeTrip(obj, amount){
    this.tripParametersDataName.arrivalTimeOrigin = this.findTripForm.value.trip.arrivalTimeOrigin;
    this.tripParametersDataName.arrivalTimeDestination = this.findTripForm.value.trip.arrivalTimeDestination;
  
  }

  //2021VR
  onChangeTripR(obj, amount){
    this.tripParametersDataName.arrivalTimeOriginR = this.findTripForm.value.tripR.arrivalTimeOrigin;
    this.tripParametersDataName.arrivalTimeDestinationR = this.findTripForm.value.tripR.arrivalTimeDestination;
    
  }

  saveTripParametersData(){
    if(this.checkIsTicket()) {
      //carico parametri di corsa per la ricerca
      this.chooseTripA(this.findTripForm.value.trip.tripId,0);
      if(this.kindRound == 'ROUNDTRIP')
        this.chooseTripR(this.findTripForm.value.tripR.tripId,0);
    }else{
      this.chooseTripA(-1,0);
      this.choseTripList();
      //this.requestResumeTmp = this.tripParametersData;
    }
    //carico categorie
    this.getCategories();
    this.searchTripList = true;
    this.tripParametersList = false;
  }

  clearCategoriesSelection() {
    this.inputTextPerson = "";
    this.groupCategories = null;
    this.groupCategoryId = 0;
  }

  getStopsTo(idStop): void {
    this.listService.getListSelect(this.pathListStops  + "/" + idStop)
    .subscribe((response) => {
      this.destinationStops = response.results;
      this.logger.log("Lista fermate fine", this.destinationStops, 300);
      },
      (error) => {
          this.logger.log("Error", error, 200);
      }
      );
  }

  getCategories() {
    this.listService.getListSelect(this.pathListCategories + "?type=" + this.kindSell + "&idCompany=" + this.destinationIdCompany)
    .subscribe((response) => {
        this.clearCategoriesSelection();
        this.groupCategories = response.results;
        this.logger.log("Lista categorie", this.groupCategories, 300);
        this.createPassengers();
      },
      (error) => {
          this.logger.log("Error", error, 200);
      }
      );
  }


  choseTripList() {
    if(this.checkIsTicket()){
      this.tripParametersData['origin'] = this.findTripForm.value.origin.stopId;
      this.tripParametersData['destination'] = this.findTripForm.value.destination.stopId;
    }
    if(this.kindRound=='ONE'){
      this.tripParametersData['dateA'] = new DatePipe('en-EN').transform(this.datepickerModel, 'yyyy-MM-dd');
      this.tripParametersData['dateR'] = '';
    } else if(this.kindRound=='ROUNDTRIPOPEN'){
      this.tripParametersData['dateA'] = new DatePipe('en-EN').transform(this.datepickerModel, 'yyyy-MM-dd');
      this.tripParametersData['dateR'] = this.tripParametersData['dateA']
    }else{
      this.tripParametersData['dateA'] = new DatePipe('en-EN').transform(this.daterangepickerModel[0], 'yyyy-MM-dd'); 
      this.tripParametersData['dateR'] = new DatePipe('en-EN').transform(this.daterangepickerModel[1], 'yyyy-MM-dd');
    }
    this.tripParametersDataName['dateA'] = this.tripParametersData['dateA'];
    this.tripParametersDataName['dateR'] = this.tripParametersData['dateR'];

    //dataRequest['passengers'] = this.passengers;
    console.log(this.tripParametersData);
    this.requestResumeTmp = this.tripParametersData;

    if(this.checkIsTicket()){
      this.getTrips(this.tripParametersData)
    }
  }

  backToChoseTripList(){
    this.cart = [];
    this.searchTripList = false;
    this.tripParametersList = true;
    this.tripParametersData={};
  }


  getTrips(dataRequest): void {
    this.logger.log("Req trip ", dataRequest, 300);
    if(this.checkIsTicket()){
      swal2.fire({ title: 'Caricamento in corso...', didOpen: function () {  swal2.showLoading()  } });
  
      this.listService.newObject(dataRequest, this.pathListTrips + "?idCompany=" + this.destinationIdCompany)
      .subscribe((response) => {
        swal2.close();
        const tripsResponse = response
        this.logger.log("Lista corse trovate ", tripsResponse, 300);
        
        if (tripsResponse.outcome.success == false) {
          swal2.fire("Errore", "Recupero delle tratte fallito.","warning");
          this.tripsAndata = null;
          this.tripsRitorno = null;
          return;
        } else if (tripsResponse.outcome.success && tripsResponse.andata.length == 0) {
          swal2.fire("Dati non disponibili", "Tratte non disponibili per la data selezionata.","warning");
        }

        if (tripsResponse.andata.length !== 0) {
          this.tripsAndata = this.orderTrips(tripsResponse.andata);
          this.originA = this.tripsAndata[0]['origin'];
          this.destinationA = this.tripsAndata[0]['destination'];
          this.tripsRitorno = this.orderTrips(tripsResponse.ritorno); 
        } else {
          this.tripsAndata = null;
        }
        
        if (tripsResponse.ritorno.length !== 0){
          this.tripsRitorno = tripsResponse.ritorno;
          this.originR = this.tripsRitorno[0]['origin'];
          this.destinationR = this.tripsRitorno[0]['destination'];
        }
        else{
          this.tripsRitorno = null;
        }
        
      },
      (error) => {
        swal2.close();
        this.logger.log("Error", error, 200);
      });
    }

    if(this.isHOHO()){
      this.searchTripList = true;
      this.tripParametersList = false;
      this.getPromotion(dataRequest);
      this.getPromotionCond(dataRequest)
    }
  }

  orderTrips(arrayTrips) {
    let result = [];
    for (let x = 0; x < arrayTrips.length; x++) {
      if (x === 0) result.push(arrayTrips[x]);
      else {
        let insertValue = false;
        for (let y = 0; y < result.length; y++) {
          if ((result[y].arrivalTimeOrigin > arrayTrips[x].arrivalTimeOrigin) && !insertValue) {
            result.splice(y, 0, arrayTrips[x]);
            insertValue = true;
          }
        }
        if (!insertValue) result.push(arrayTrips[x])
      }
    }
    return result;
  }

  chooseTripA(chooseId, amount) {
    //console.log(this.tripIdA + " - " + amount)
    //se fare amount == null allora non fa nulla
    if(amount == null) return
    this.tripParametersData['tripIdA'] = chooseId;
    // console.log(this.tripIdA);
    //console.log("tripIdA",this.tripParametersData['tripIdA'])
  }   

  chooseTripR(chooseId, amount) {
    if(amount == null) return
    this.tripParametersData['tripIdR'] = chooseId
    //console.log(this.tripParametersData['tripIdR'])
    // console.log(this.tripIdR);
  }
    
  changeChooseA() {
    this.tripParametersData['tripIdA'] = -1;
  }

  changeChooseR() {
    this.tripParametersData['tripIdR'] = -1;
  }

  getPromotion(dataRequest): void {
    swal2.fire({ title: 'Caricamento in corso...', didOpen: function () {  swal2.showLoading()  } });
    this.listService.newObject(dataRequest ,this.pathListTripsOption)
    .subscribe((response) => {
      this.requestToSendAfterPromotion = dataRequest;
      swal2.close();
      this.arrayPromotionOptional = response.results;
      this.logger.log("Lista promozioni trovate ", this.arrayPromotionOptional, 300);
      
    },
    (error) => {
      swal2.close();
      this.logger.log("Error", error, 200);
    });
  }

  getPromotionCond(dataRequest): void {
    swal2.fire({ title: 'Caricamento in corso...', didOpen: function () {  swal2.showLoading()  } });
    this.listService.getListSelect(this.pathListTripsPromoTags)
    .subscribe((response) => {
      this.requestToSendAfterPromotion = dataRequest;
      swal2.close();
      this.arrayPromotionConditional = response.results;
      this.logger.log("Lista promozioni condizionali ", this.arrayPromotionConditional, 300);
      
    },
    (error) => {
      swal2.close();
      this.logger.log("Error", error, 200);
    });
  }

  getResume(dataRequest): void {
    swal2.fire({ title: 'Caricamento in corso...', didOpen: function () {  swal2.showLoading()  } });

    //GESTIONE EVENTUALE PROMOZIONE
    var pathTripsResume = this.pathListTripsResume + "?idCompany=" + this.destinationIdCompany;
    //if(this.idPromozioneApplicata != 0 ){
      pathTripsResume = pathTripsResume + "&idPromotionOption=" + this.idPromozioneApplicata;
    //}

    //aggiungo promo condizionale se selezionata
    dataRequest = this.addPromoConditionalIntoPassengers(dataRequest);
    this.logger.log("dataRequest after condizionali ",dataRequest, 300);

    this.listService.newObject(dataRequest ,pathTripsResume)
    .subscribe((response) => {
      swal2.close();
      if(response.outcome.success == true){
        
        const tripsResponse = response;
        this.logger.log("Lista viaggi trovate ", tripsResponse, 300);
        this.tripsAndataResume = tripsResponse.andata[0];
        this.tripsAndataResume['dateA'] =  dataRequest['dateA'] 
        // this.completeTripsData(tripsResponse.andata);
        if (tripsResponse.ritorno.length !== 0){
          this.tripsRitornoResume = tripsResponse.ritorno[0];
          this.tripsRitornoResume['dateR'] = dataRequest['dateR']
          this.cart.push(this.tripsAndataResume)
          this.cart.push(this.tripsRitornoResume)
        }
        // this.completeTripsData(tripsResponse.ritorno);
        else{
          this.cart.push(this.tripsAndataResume)
          this.tripsRitornoResume = null;
        }


        //2021VR Controlla se il carrello è valido oppure ha amount a null
        this.checkCorrectCart();
        //console.log(this.cart)
        
        this.totalCart();
        //AZZERO PROMOZIONE
        this.idPromozioneApplicata = 0;
      }else{
        swal2.fire("Errore", response.outcome.message + "("+response.outcome.code+"). Inserisci nuovamente i parametri e riprova.","error");

      }
    },
    (error) => {
      swal2.close();
      this.logger.log("Error", error, 200);
    });
  }

  //aggiunge il tag ai passeggeri oppure mette vuoto per non passar nulla
  addPromoConditionalIntoPassengers(dataR) {
    
      for(let passenger of dataR.passengers){
        if(this.idPromozioneCondizionale != '0' && this.idPromozioneCondizionale != null)
          passenger.tag = this.idPromozioneCondizionale;
        else
          passenger.tag = '';
      }
      return dataR;
  }
  
  bestPrice(wayA, wayR) {
    if (wayA <= wayR) return wayA; else return wayR;
  }

  checkCorrectCart(){
    for(let c of this.cart){
      if(c.fare.amount == null) {
        this.isCorrectCart = false;
        return;
      }
    }
    this.isCorrectCart = true;
  }
    
  totalCart(){
    this.total = 0.00;
    for(let c of this.cart){

      if (c.promotion.amount !== 0 && c.fare.amount > c.promotion.amount) {
        this.total += this.findTripForm.value.supplement ? c.promotion.totalAmount : c.promotion.amount;
      } else {
        this.total += this.findTripForm.value.supplement ? c.fare.totalAmount : c.fare.amount;
      }
    }
  }

  removeFromCart(data){
    this.cart = this.cart.filter(obj => obj !== data);
    this.totalCart()
  }

  emptyCart(){
    this.cart = [];
    this.totalCart()
  }


  putIntoCart(){

    //2021VR this.searchTripList = false;
    //2021VR 
    this.emptyCart();

    this.findTripForm.value.supplement = true;

    //const dataRequest = {};

    this.requestToSendAfterPromotion['origin'] = this.requestResumeTmp['origin']
    this.requestToSendAfterPromotion['destination'] = this.requestResumeTmp['destination']
    if (this.isRoundTrip === false) {
        // console.log(this.datepickerModel);
        this.requestToSendAfterPromotion['dateA'] = this.requestResumeTmp['dateA']
        this.requestToSendAfterPromotion['dateR'] = this.requestResumeTmp['dateR']
        this.requestToSendAfterPromotion['tripIdA'] = this.tripParametersData['tripIdA']
      
    } else {
      this.requestToSendAfterPromotion['dateA'] = this.requestResumeTmp['dateA']
      this.requestToSendAfterPromotion['dateR'] = this.requestResumeTmp['dateR']
      this.requestToSendAfterPromotion['tripIdA'] = this.tripParametersData['tripIdA']
      this.requestToSendAfterPromotion['tripIdR'] = this.tripParametersData['tripIdR']
    }
    this.requestToSendAfterPromotion['passengers'] = this.passengers;
    
    this.logger.log("ResumeAfterPromo", this.requestToSendAfterPromotion, 200);
    this.getResume(this.requestToSendAfterPromotion);

  }



  initializeSoldTicket(){
    //2021VR Indipendentemente da come va, cercherò i dati di impostazione corsa così da riusarli dopo
    
    localStorage.setItem("tripParametersData", JSON.stringify(this.tripParametersData));
    localStorage.setItem("tripParametersDataName", JSON.stringify(this.tripParametersDataName));
    
    var  tickets = new TicketCreate();
    
    if(localStorage.getItem("deskId") != null &&  localStorage.getItem("deskId") != undefined && localStorage.getItem("deskId") != ""){
      tickets.idDesk = localStorage.getItem("deskId")
    }

    this.logger.log("CARRELLO ", this.cart, 200);

    for(let t of this.cart){

      if(this.serialNumber == undefined || this.serialNumber == null){
        //CONTROLLO POI ELIMINO
        localStorage.setItem("PDF", "printPDF")

        //CONTROLLO E SALVO IL TEMPLATE SCELTO
        if( this.findTripForm.controls.idPrinter.value ) 
          localStorage.setItem("templatePDF", this.findTripForm.controls.idPrinter.value);
      } else {
        //console.log("VERIFICA SERIAL NUMBER", this.serialNumber)
        localStorage.setItem("PDF", "noPDF")
      }

      if (t.promotion.amount !== 0 && t.fare.amount > t.promotion.amount) {

        for(let p of t.promotion.passengerList){

          for (var _i = 0; _i < p.total; _i++) {
          
            var ticket = new Ticket();

            var stringDate = ""
            {
              if(t.dateA != null) { //SE BIGLIETTO ANDATA
                stringDate = t.dateA + " "
                if(t.arrivalTimeOrigin != null)
                  stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                else
                  stringDate = stringDate + "00:00:00"
                //INSERISCO TOLLERANZA
                ticket.tolerance= TOLERANCE_BASE
              } else {
                //CONTROLLO SE DATA APERTA O MENO
                if(this.kindRound == 'ROUNDTRIPOPEN') {
                  t.dateR = t.dateR+'T00:00:00';
                  let sixMonthsLater = new Date(t.dateR);
                  sixMonthsLater.setMonth( sixMonthsLater.getMonth() + TOLERANCE_MONTHS )
                  t.dateR = new DatePipe('en-EN').transform(sixMonthsLater, 'yyyy-MM-dd');
                  stringDate = t.dateR + " "
                  if(t.arrivalTimeOrigin != null)
                    stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                  else  
                    stringDate = stringDate + "00:00:00"
                  ticket.tolerance= TOLERANCE_6_MONTHS
                  this.logger.log("Data aperta", t.dateR, 200);
                } else {
                  stringDate = t.dateR + " "
                  if(t.arrivalTimeOrigin != null)
                    stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                  else  
                    stringDate = stringDate + "00:00:00"
                  ticket.tolerance= TOLERANCE_BASE
                  this.logger.log("Ritorno base", t.dateR, 200);
                }
              }
            }
            if ( p.promotionId == 0) {
              ticket.idFare = t.idFare
            } else {
              ticket.idPromotion = p.promotionId
            }
  
            ticket.idCompany= this.destinationIdCompany
            ticket.usableFrom= stringDate,
            ticket.idTrip= t.tripId,
            ticket.price= p.amount,
            ticket.idTicketChannel= this.channel,
            ticket.printerSerialNumber= this.serialNumber,
            ticket.origin= t.origin,
            ticket.destination= t.destination
            ticket.category = p.categoryDescription
            ticket.idCategory = p.categoryId
            ticket.supplementPrice =  this.findTripForm.value.supplement ? t.fare.supplementAmount : 0;

            tickets.tickets.push(ticket)
          }
        }
        
      } else {

        for(let f of t.fare.passengerList){

          for (var _i = 0; _i < f.total; _i++) {
          
            var ticket = new Ticket();

            var stringDate = ""
            {
              if(t.dateA != null) { //SE BIGLIETTO ANDATA
                stringDate = t.dateA + " "
                if(t.arrivalTimeOrigin != null)
                  stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                else
                  stringDate = stringDate + "00:00:00"
                //INSERISCO TOLLERANZA
                ticket.tolerance= TOLERANCE_BASE
              } else { //SE BIGLIETTO RITORNO
                //CONTROLLO SE DATA APERTA O MENO
                if(this.kindRound == 'ROUNDTRIPOPEN'){
                  
                  t.dateR = t.dateR+'T00:00:00';
                  let sixMonthsLater = new Date(t.dateR);
                  sixMonthsLater.setMonth( sixMonthsLater.getMonth() + TOLERANCE_MONTHS )
                  t.dateR = new DatePipe('en-EN').transform(sixMonthsLater, 'yyyy-MM-dd');
                  if(t.arrivalTimeOrigin != null)
                    stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                  else  
                    stringDate = stringDate + "00:00:00"
                  ticket.tolerance= TOLERANCE_6_MONTHS
                  this.logger.log("Data aperta", t.dateR, 200);
                } else {
                  stringDate = t.dateR + " "
                  if(t.arrivalTimeOrigin != null)
                    stringDate = stringDate + t.arrivalTimeOrigin + ":00"
                  else  
                    stringDate = stringDate + "00:00:00"
                  ticket.tolerance= TOLERANCE_BASE
                  this.logger.log("Ritorno base", t.dateR, 200);
                }
              }
            }

            ticket.idCompany= this.destinationIdCompany
            ticket.usableFrom= stringDate,
            ticket.idTrip= t.tripId,
            ticket.idFare = f.fareId
            
            ticket.price= f.amount,
            ticket.idTicketChannel= this.channel,
            ticket.printerSerialNumber= this.serialNumber,
            ticket.origin= t.origin,
            ticket.destination= t.destination
            ticket.category = f.categoryDescription
            ticket.idCategory = f.categoryId
            ticket.supplementPrice =  this.findTripForm.value.supplement ? t.fare.supplementAmount : 0;

            tickets.tickets.push(ticket)

          }
        }

      }

    }

    this.sendTicketToServer(tickets);

  }



  sendTicketToServer(tickets){

    swal2.fire({ title: 'Caricamento in corso...', didOpen: function () {  swal2.showLoading()  } });
    this.listService.newObject(tickets ,this.pathListInitTicket)
    .subscribe((response) => {
      swal2.close();
      const respo = response;  
      console.log("RISPOSTA STAMPANTE TICKET", respo.results)

      if (respo.outcome.success === true) {
        var count = 0;

        console.log("RISULTATO DA INVIARE AL SOMMARIO", response.results);
        for (let t of response.results) {
          if (t.outcome.success == true) {
            this.toastr.success('Biglietto correttamente stampato', 'Vendita completata');
            this.dataResponseTickets.push(t);
          }
          if (t.outcome.code == "0019") {
            count = count + 1;
          this.toastr.error(
           "",
           "Il biglietto non è stato stampato perchè la stampante non è pronta per la stampa"
         );
           this.dataResponseTickets.push(t);
          }
          if (t.outcome.code == "0018") {
            count = count + 1;
            this.toastr.error(
              "",
              "Il biglietto non è stato stampato perchè la stampante non è raggiungibile"
            );
            this.dataResponseTickets.push(t);
          }

          if (t.outcome.code == "0020") {
            count = count + 1;
            this.toastr.error("", "Il biglietto non è stato stampato");
            this.dataResponseTickets.push(t);
          }

          if (t.outcome.code == "0021") {
            count = count + 1;
            this.toastr.error(
              "",
              "Il biglietto non è stato stampato perchè non è stata individuata la stampante"
            );
            this.dataResponseTickets.push(t);
          }

          if (t.outcome.code == "0022") {
            count = count + 1;
           this.toastr.error(
             "",
             "Il biglietto non è stato stampato perchè la stampante non è raggiungibile"
           );
            this.dataResponseTickets.push(t);
          }

          if (t.outcome.code == "0023") {
           count = count + 1;
           this.toastr.error("", "Il biglietto non è stato stampato");
            this.dataResponseTickets.push(t);
          }

          if (t.outcome.code == "0024" || t.outcome.code == "0025") {
            count = count + 1;
           this.toastr.error("", "Errore nella stampa del biglietto");
            this.dataResponseTickets.push(t);
          }
          
          if (t.outcome.code == "0030") {
            count = count + 1;
            this.toastr.error("", "Errore creazione biglietti");
            this.dataResponseTickets.push(t);
          }

          console.log("ERRORI STAMPA", count);
        }

        if (count == 0 && (this.serialNumber != null || this.serialNumber != undefined)) {
          swal2.fire("La stampa è avvenuta con successo", "", "success");
        } else if(count > 0 ) {
          swal2.fire(
            "Attenzione",
            "Alcuni biglietti potrebbero non essere stati stampati",
            "warning"
          );
        }

        //PASSARE AL SOMMARIO IN QUESTO PUNTO PASSANDOGLI L'ARRAY RICEVUTO DAL SERVER
        localStorage.setItem(
          "dataResponseTicket",
          JSON.stringify(this.dataResponseTickets)
        );
        console.log("ARRIVO", this.dataResponseTickets);
        this.router.navigate(["/summarySale"]);

      }
      else {
        if (response.outcome.code == "0013") {
          this.errorMessage =
            "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
        } else if (response.outcome.code == "0015") {
          this.errorMessage =
            "Il credito all'interno del wallet non è sufficiente per completare l'acquisto";
        } else {
          this.errorMessage = "Generic Error";
        }

        swal2.fire("Attenzione", this.errorMessage, "warning");
      }
    },(error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage = "Spiacente, si è verificato un errore tecnico.";
        swal2.fire(
          "Errore",
          "Spiacente, si è verificato un errore tecnico.",
          "error"
        );
    });
  }

  onSupplementChange(): void {
    this.totalCart();
  }

}


export class Ticket {
  id;
  idCompany;
  idLinkedTicket;
  idTicketPricelist;
  idTicketChannel;
  idTrip;
  usableFrom;
  usableTo;
  idPromotion;
  idFare;
  idCategory;
  tolerance;
  price;
  printerSerialNumber;
  origin;
  destination;
  category;
  supplementPrice: number;
  
  constructor() {}
}

export class TripParamsName{
  origin;
  destination;
  arrivalTimeOrigin;
  arrivalTimeDestination;
  arrivalTimeOriginR;
  arrivalTimeDestinationR;
  dataA;
  dataR;
  kindRound;
  kindSell;
  constructor() {}
}

export class TicketCreate {
  
  tickets: any[] = Array();
  idDesk;

  constructor() {}
}
